import React, { useState, useEffect, useRef, useCallback } from "react";
import { Helmet } from "react-helmet";
import {
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { colors } from "../lib/theme";
import { db } from "../../shared/firebase";
import { useAuth } from "../../shared/useAuth";
import AlbumHeader from "./AlbumHeader";
import AlbumCover from "./AlbumCover";
import TrackList from "./TrackList";
import ConfirmDialog from '../../shared/ConfirmDialog';
import AlertDialog from '../../shared/AlertDialog';
import AlbumSettings from './AlbumSettings';
import useAudioPlayer from '../../shared/useAudioPlayer';

const reactRootDataset = document.getElementById("react-root").dataset;

const Album = () => {
  const maxRecordingTime = 2700; // 45 minutes

  // State variables
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [creatorName, setCreatorName] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tracks, setTracks] = useState([]);
  const { user } = useAuth();
  const albumId = reactRootDataset.albumId;
  const [userId, setUserId] = useState("");
  const [privacyLevel, setPrivacyLevel] = useState(null);
  const [sharedWith, setSharedWith] = useState([]);
  const navigate = useNavigate();
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTrackId, setRecordingTrackId] = useState(null);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const [editingTrackId, setEditingTrackId] = useState(null);
  const [editingTrackTitle, setEditingTrackTitle] = useState("");
  const [newTrackTitle, setNewTrackTitle] = useState("");
  const [isAddingTrack, setIsAddingTrack] = useState(false);
  const [browserNotSupported, setBrowserNotSupported] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogContent, setDialogContent] = useState('');
  const [dialogAction, setDialogAction] = useState(null);
  const [invitedTo, setInvitedTo] = useState([]);
  const [recordingStartTime, setRecordingStartTime] = useState(null);
  const [remainingTime, setRemainingTime] = useState(maxRecordingTime);

  const {
    isPlaying,
    playingTrackId,
    selectedTrackId,
    currentTime,
    duration,
    handlePlayPause,
    handleSeek,
    audioRef,
    playingSegmentIndex,
    handleDeleteSegment,
  } = useAudioPlayer(albumId, userId, user);

  // Fetch album data
  useEffect(() => {
    const fetchAlbum = async () => {
      console.log("Fetching album with ID:", albumId);
      setLoading(true);
      setError(null);

      if (!albumId) {
        console.error("No albumId provided");
        setError("No album ID provided");
        setLoading(false);
        return;
      }

      try {
        const albumDoc = doc(db, "albums", albumId);
        console.log("Fetching album document...");
        const albumSnapshot = await getDoc(albumDoc);

        if (albumSnapshot.exists()) {
          console.log("Album document found");
          const albumData = albumSnapshot.data();
          console.log("Album data:", albumData);

          setTitle(albumData.title || "");
          setSubTitle(albumData.subTitle || "");
          setImageUrl(albumData.imageUrl || "");

          const tracksWithIds = (albumData.tracks || []).map(
            (track, index) => ({
              ...track,
              id: track.id || `track-${index}`,
            })
          );
          setTracks(tracksWithIds);

          if (albumData.userId) {
            console.log("Fetching creator info for userId:", albumData.userId);
            const creatorDoc = await getDoc(doc(db, "users", albumData.userId));
            if (creatorDoc.exists()) {
              const { firstName, lastName } = creatorDoc.data();
              setCreatorName(`${firstName} ${lastName}`);
            } else {
              console.log("Creator document not found");
            }
          }
          setUserId(albumData.userId || "");

          // Set privacy level and sharedWith
          setPrivacyLevel(albumData.privacyLevel);
          setSharedWith(albumData.sharedWith || []);
          setInvitedTo(albumData.invitedTo || []);

          // Check privacy settings
          if (user) {
            if (albumData.privacyLevel === 0 && albumData.userId !== user.uid) {
              navigate("/login");
              return;
            }
            if (
              albumData.privacyLevel === 1 &&
              albumData.userId !== user.uid &&
              !albumData.sharedWith.includes(user.uid)
            ) {
              navigate("/login");
              return;
            }
          } else {
            return;
          }
        } else {
          console.log("Album document not found");
          setError("Album not found");
        }
      } catch (error) {
        navigate(`/login?redirect_to=/album/${albumId}`);
      } finally {
        setLoading(false);
      }
    };

    fetchAlbum();

    // Real-time listener
    if (albumId) {
      const albumRef = doc(db, "albums", albumId);
      const unsubscribe = onSnapshot(albumRef, (doc) => {
        if (doc.exists()) {
          const albumData = doc.data();
          setTitle(albumData.title || "");
          setSubTitle(albumData.subTitle || "");
          setImageUrl(albumData.imageUrl || "");

          const tracksWithIds = (albumData.tracks || []).map(
            (track, index) => ({
              ...track,
              id: track.id || `track-${index}`,
            })
          );
          setTracks(tracksWithIds);

          setPrivacyLevel(albumData.privacyLevel);
          setSharedWith(albumData.sharedWith || []);
          setInvitedTo(albumData.invitedTo || []);
        }
      });

      // Clean up listener
      return () => unsubscribe();
    }
  }, [albumId, user, navigate]);

  // Handlers
  const startRecording = useCallback(
    async (trackId) => {
      console.log("startRecording called with trackId:", trackId);

      if (!trackId) {
        console.error("No trackId provided to startRecording");
        return;
      }

      if (!user || (user.uid !== userId && !sharedWith.includes(user.uid))) {
        setDialogTitle('Permission Denied');
        setDialogContent("You don't have permission to record on this album.");
        setAlertDialogOpen(true);
        return;
      }

      try {
        console.log("Requesting microphone permission...");
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        console.log("Microphone permission granted");

        const mimeType = "audio/mp4";

        console.log("Using mimeType:", mimeType);

        mediaRecorderRef.current = new MediaRecorder(stream, {
          mimeType
        });
        chunksRef.current = [];

        mediaRecorderRef.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            chunksRef.current.push(event.data);
          }
        };

        mediaRecorderRef.current.onstop = async () => {
          console.log("Recording stopped, processing audio...");
        };

        mediaRecorderRef.current.start();
        setIsRecording(true);
        setRecordingTrackId(trackId);
        setRecordingStartTime(Date.now());
        setRemainingTime(maxRecordingTime);
        console.log("Recording started for track:", trackId);
      } catch (error) {
        console.error("Error starting recording:", error);
        if (error.name === "NotAllowedError") {
          setDialogTitle('Microphone Access Denied');
          setDialogContent('Microphone access was denied. Please check your browser settings and ensure that you\'ve granted permission for this site to use your microphone.');
          setAlertDialogOpen(true);
        } else if (error.name === "NotFoundError") {
          setDialogTitle('Microphone Not Found');
          setDialogContent('No microphone was found. Please ensure that a microphone is connected and try again.');
          setAlertDialogOpen(true);
        } else if (error.name === "NotSupportedError") {
          setBrowserNotSupported(true);
        } else {
          setDialogTitle('Error');
          setDialogContent(`An error occurred while trying to start recording: ${error.message}`);
          setAlertDialogOpen(true);
        }
      }
    },
    [tracks, user, userId, sharedWith]
  );

  const stopRecording = useCallback(async () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setRecordingTrackId(null);
      setRecordingStartTime(null);
      setRemainingTime(maxRecordingTime);

      await new Promise((resolve) => {
        mediaRecorderRef.current.onstop = async () => {
          const audioBlob = new Blob(chunksRef.current, { type: "audio/mp4" });

          console.log("User ID:", userId);

          try {
            const timestamp = Date.now();
            const m4aFileName = `segment-${timestamp}.m4a`;

            const storage = getStorage();
            const m4aPath = `audio_segments/${user.uid}/${albumId}/${m4aFileName}`;

            // Upload M4A file
            const m4aRef = ref(storage, m4aPath);
            await uploadBytes(m4aRef, audioBlob);

            const trackIndex = tracks.findIndex(
              (track) => track.id === recordingTrackId
            );
            if (trackIndex !== -1) {
              const updatedTracks = [...tracks];
              const newAudioSegment = {
                id: timestamp.toString(),
                recordedBy: user.uid,
                updatedAt: new Date(),
              };
              updatedTracks[trackIndex].audioSegments =
                updatedTracks[trackIndex].audioSegments || [];
              updatedTracks[trackIndex].audioSegments.push(newAudioSegment);
              updatedTracks[trackIndex].updatedAt = new Date();

              const albumRef = doc(db, "albums", albumId);
              await updateDoc(albumRef, {
                tracks: updatedTracks,
                updatedAt: new Date(),
              });

              // After update, fetch the latest data to get the server-generated timestamps
              const updatedDoc = await getDoc(albumRef);
              if (updatedDoc.exists()) {
                setTracks(updatedDoc.data().tracks);
              }
            }

            console.log("Recording uploaded and database updated");
          } catch (error) {
            console.error("Error uploading recording:", error);
          }

          resolve();
        };
      });
    }
  }, [isRecording, recordingTrackId, tracks, userId, albumId, user]);

  useEffect(() => {
    let intervalId;
    if (isRecording && recordingStartTime) {
      intervalId = setInterval(() => {
        const elapsedTime = Math.floor((Date.now() - recordingStartTime) / 1000);
        const remaining = Math.max(maxRecordingTime - elapsedTime, 0);
        setRemainingTime(remaining);

        if (remaining === 0) {
          stopRecording();
        }
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isRecording, recordingStartTime, stopRecording]);

  const handleImageClick = (fileInputRef) => {
    if (user && (user.uid === userId || sharedWith.includes(user.uid))) {
      fileInputRef.current.click();
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const storage = getStorage();
      const imageRef = ref(storage, `album_photos/${albumId}/cover.jpg`);
      await uploadBytes(imageRef, file);
      const newImageUrl = await getDownloadURL(imageRef);

      const albumRef = doc(db, "albums", albumId);
      await updateDoc(albumRef, { imageUrl: newImageUrl });

      setImageUrl(newImageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
      setDialogTitle('Error');
      setDialogContent('Failed to upload image. Please try again.');
      setAlertDialogOpen(true);
    }
  };

  const handleEditTrack = (trackId, currentTitle) => {
    setEditingTrackId(trackId);
    setEditingTrackTitle(currentTitle);
  };

  const handleSaveTrackTitle = async () => {
    if (!editingTrackId) return;

    try {
      const updatedTracks = tracks.map((track) =>
        track.id === editingTrackId
          ? { ...track, title: editingTrackTitle }
          : track
      );

      const albumRef = doc(db, "albums", albumId);
      await updateDoc(albumRef, {
        tracks: updatedTracks,
        updatedAt: new Date()
      });

      setTracks(updatedTracks);
      setEditingTrackId(null);
    } catch (error) {
      console.error("Error updating track title:", error);
      setDialogTitle('Error');
      setDialogContent('Failed to update track title. Please try again.');
      setAlertDialogOpen(true);
    }
  };

  const handleCancelEdit = () => {
    setEditingTrackId(null);
    setEditingTrackTitle("");
  };

  const handleDeleteTrack = useCallback(
    async (trackId) => {
      if (!user || (user.uid !== userId && !sharedWith.includes(user.uid))) {
        setDialogTitle('Permission Denied');
        setDialogContent("You don't have permission to delete tracks from this album.");
        setAlertDialogOpen(true);
        return;
      }

      setDialogTitle('Confirm Delete');
      setDialogContent('Are you sure you want to delete this track? It will remove all of the recordings associated with it. This action cannot be undone.');
      setDialogAction(() => async () => {
        try {
          const trackIndex = tracks.findIndex((track) => track.id === trackId);
          if (trackIndex === -1) throw new Error("Track not found");

          const track = tracks[trackIndex];
          const updatedTracks = tracks.filter((t) => t.id !== trackId);

          const storage = getStorage();
          if (track.audioSegments) {
            for (const segment of track.audioSegments) {
              const m4aPath = `audio_segments/${userId}/${albumId}/segment-${segment.id}.m4a`;

              try {
                await deleteObject(ref(storage, m4aPath));
              } catch (error) {
                console.log("M4A file not found or already deleted");
              }
            }
          }

          const albumRef = doc(db, "albums", albumId);
          await updateDoc(albumRef, {
            tracks: updatedTracks,
            updatedAt: new Date()
          });

          setTracks(updatedTracks);
          console.log("Track deleted successfully");
        } catch (error) {
          console.error("Error deleting track:", error);
          setDialogTitle('Error');
          setDialogContent('Failed to delete track. Please try again.');
          setAlertDialogOpen(true);
        }
      });
      setConfirmDialogOpen(true);
    },
    [tracks, user, userId, sharedWith, albumId]
  );

  const handleAddTrack = async () => {
    if (!newTrackTitle.trim()) {
      setDialogTitle('Error');
      setDialogContent('Please enter a track title.');
      setAlertDialogOpen(true);
      return;
    }

    try {
      const newTrack = {
        id: `track-${Date.now()}`,
        title: newTrackTitle.trim(),
        audioSegments: [],
        updatedAt: new Date(),
      };

      const updatedTracks = [...tracks, newTrack];

      const albumRef = doc(db, "albums", albumId);
      await updateDoc(albumRef, {
        tracks: updatedTracks,
        updatedAt: new Date()
      });

      setTracks(updatedTracks);
      setNewTrackTitle("");
      setIsAddingTrack(false);
    } catch (error) {
      console.error("Error adding new track:", error);
      setDialogTitle('Error');
      setDialogContent('Failed to add new track. Please try again.');
      setAlertDialogOpen(true);
    }
  };

  const handlePrivacyChange = async (newLevel) => {
    try {
      const albumRef = doc(db, "albums", albumId);
      await updateDoc(albumRef, {
        privacyLevel: newLevel,
        updatedAt: new Date()
      });
      setPrivacyLevel(newLevel);
    } catch (error) {
      console.error("Error updating privacy level:", error);
      setDialogTitle('Error');
      setDialogContent('Failed to update privacy level. Please try again.');
      setAlertDialogOpen(true);
    }
  };

  console.log("Creator Name:", creatorName);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress sx={{ color: colors.gold }} />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <>
      <Helmet>
        <title>{title} - Golden Record</title>
        <meta
          name="apple-itunes-app"
          content={`app-id=6501951254, app-argument=https://app.goldenrecord.app/album/${albumId}`}
        />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={subTitle} />
        <meta
          property="og:url"
          content={`https://app.goldenrecord.app/album/${albumId}`}
        />
      </Helmet>
      {browserNotSupported && (
        <AlertDialog
          open={alertDialogOpen}
          title="Browser Not Supported"
          content="Your browser does not support audio recording. Please use a modern browser like Chrome, Firefox, or Edge to record audio."
          onClose={() => setAlertDialogOpen(false)}
          okText="OK"
          okButtonProps={{ color: 'primary' }}
        />
      )}
      <AlbumHeader
        albumId={albumId}
        title={title}
        subTitle={subTitle}
        creatorName={creatorName}
        userId={userId}
        currentUserId={user ? user.uid : null}
      />
      <Grid container spacing={4} sx={{ mt: 2 }}>
        <Grid item xs={12} md={4}>
          <AlbumCover
            imageUrl={imageUrl}
            handleImageClick={handleImageClick}
            handleImageUpload={handleImageUpload}
            user={user}
            userId={userId}
            sharedWith={sharedWith}
          />
          {user && user.uid === userId && (
            <AlbumSettings
              privacyLevel={privacyLevel}
              onPrivacyChange={handlePrivacyChange}
              user={user}
              userId={userId}
              albumId={albumId}
              title={title}
              initialSharedWith={sharedWith}
              imageUrl={imageUrl}
              creatorName={creatorName}
              invitedTo={invitedTo}
            />
          )}
        </Grid>
        <Grid item xs={12} md={7}>
          <TrackList
            tracks={tracks}
            isPlaying={isPlaying}
            playingTrackId={playingTrackId}
            handlePlayPause={handlePlayPause}
            currentTime={currentTime}
            duration={duration}
            handleSeek={handleSeek}
            user={user}
            userId={userId}
            sharedWith={sharedWith}
            isRecording={isRecording}
            recordingTrackId={recordingTrackId}
            selectedTrackId={selectedTrackId}
            stopRecording={stopRecording}
            startRecording={startRecording}
            editingTrackId={editingTrackId}
            editingTrackTitle={editingTrackTitle}
            handleEditTrack={handleEditTrack}
            handleSaveTrackTitle={handleSaveTrackTitle}
            handleCancelEdit={handleCancelEdit}
            setEditingTrackTitle={setEditingTrackTitle}
            handleDeleteTrack={handleDeleteTrack}
            isAddingTrack={isAddingTrack}
            setIsAddingTrack={setIsAddingTrack}
            newTrackTitle={newTrackTitle}
            setNewTrackTitle={setNewTrackTitle}
            handleAddTrack={handleAddTrack}
            albumId={albumId}
            showTrackControls={true}
            remainingTime={remainingTime}
            playingSegmentIndex={playingSegmentIndex}
            handleDeleteSegment={handleDeleteSegment}
          />
        </Grid>
      </Grid>
      <ConfirmDialog
        open={confirmDialogOpen}
        title={dialogTitle}
        content={dialogContent}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={() => {
          if (dialogAction) {
            dialogAction();
          }
          setConfirmDialogOpen(false);
        }}
        confirmText="Confirm"
        cancelText="Cancel"
        confirmButtonProps={{ color: 'error' }}
        cancelButtonProps={{ color: 'primary' }}
      />
      <AlertDialog
        open={alertDialogOpen}
        title={dialogTitle}
        content={dialogContent}
        onClose={() => setAlertDialogOpen(false)}
        okText="OK"
        okButtonProps={{ color: 'primary' }}
      />
    </>
  );
};

export default Album;
